<template>
  <div>
    <v-timeline
      align-center
      dense
    >
      <v-timeline-item
        v-for="event in show?events:recentThreeEvents"
        :key="event.id"
        :color="itemDict[event.tag].color"
        :icon="itemDict[event.tag].icon"
        fill-dot
        small
      >
        <div>
          <div class="font-weight-normal">
          <strong>{{ event.date }}</strong>
          </div>
          <div>{{ event.content }}</div>
        </div>
      </v-timeline-item>
    </v-timeline>
<!--
<v-btn icon @click="show = !show" class="ml-7">
  <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
</v-btn>
-->
  </div>
</template>

<script>
export default {
  name: 'News',

  methods: {
  },

  data () {
    return {
      show: false,
      itemDict: {
        'Life': {
          color: 'amber darken-2',
          icon: 'mdi-check-bold'
        },
        'Research': {
          color: 'indigo',
          icon: 'mdi-star'
        }
      },
      events: [
        {
          date: '2025-01',
          tag: 'Research',
          content: 'Our paper investigating user relations with WavData Lamp was accepted at CHI\'25!',
        },
        {
          date: '2024-05',
          tag: 'Research',
          content: 'Our paper investigating various visual encodings for guiding precise hand gestures in AR/VR was accepted and present at CHI\'24!',
        },
        {
          date: '2022-09',
          tag: 'Life',
          content: 'I started my CS in Ph.D. program and joined WatVis at the University of Waterloo.',
        },
        {
          date: '2020-09',
          tag: 'Life',
          content: 'I started working in Xuzhishi as a product manager.',
        },
        {
          date: '2020-04',
          tag: 'Life',
          content: 'I graduated from the University of Michigan - Ann Arbor.',
        },
        {
          date: '2020-01',
          tag: 'Research',
          content: 'Our CHI\'20 full paper [MRAT] was accepted.',
        },
        {
          date: '2019.05',
          tag: 'Research',
          content: 'We presented our design [Shadoji] at the CHI\'19 Conferenve in Glasgow, UK.',
        },
        {
          date: '2019.01',
          tag: 'Research',
          content: 'Our design [Shadoji] was accepted into the CHI Student Design Competition finalist.',
        },
        {
          date: '2018.11',
          tag: 'Research',
          content: 'Our CHIIR\'19 full paper [HAIR, also called Self-updating AA Meeting List] was accepted.',
        },
        {
          date: '2018.10',
          tag: 'Life',
          content: 'I joined the Information Interaction Lab.',
        },
        {
          date: '2018.09',
          tag: 'Life',
          content: 'I started my master program at University of Michigan - Ann Arbor.',
        },
        {
          date: '2018.08',
          tag: 'Life',
          content: 'I deployed Moment Capsule in the Google Play Store.',
        },
        {
          date: '2017.12',
          tag: 'Research',
          content: 'Our CSCW 2019 full paper [SqueezeBands, also called Mediated Social Touch] was accepted.',
        },
        {
          date: '2017.12',
          tag: 'Life',
          content: 'Welcome to Lucy\'s Personal Website!',
        }
      ]
    }
  },
  computed: {
    recentThreeEvents: function() {return this.events.slice(0, 3)}
  }
}
</script>
